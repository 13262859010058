class LocalEventsManager {
  constructor() {
    this.listeners = {};
  }

  notify(eventKey, data) {
    if (this.listeners[eventKey]) {
      this.listeners[eventKey] = this.listeners[eventKey].filter(listener => !listener.isUnsubscribed);
      this.listeners[eventKey].forEach((listener) => {
        listener.cb(data);
      });
      this.listeners[eventKey] = this.listeners[eventKey].filter(listener => listener.type !== "once" && !listener.isUnsubscribed);
    }
  }

  subsrcibeOnce(eventKey, cb) {
    if (this.listeners[eventKey]) {
      this.listeners[eventKey].push({ cb, type: "once" });
    } else {
      this.listeners[eventKey] = [{ cb, type: "once" }];
    }
  }

  subscribeAlways(eventKey, cb) {
    const subscription = { cb, type: "always", unsubscribe: () => null, isUnsubscribed: false };
    subscription.unsubscribe = () => {
      subscription.isUnsubscribed = true;
    }
    if (this.listeners[eventKey]) {
      this.listeners[eventKey].push(subscription);
    } else {
      this.listeners[eventKey] = [subscription];
    }
    return subscription;
  }

  removeListeners(eventKey) {
    delete this.listeners[eventKey];
  }
}

const localEventsManager = new LocalEventsManager();
export default localEventsManager;
