import React from "react";
import { Menu, Popover, Badge, Typography, Space, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import {
  logout,
  getOrganizations,
  getSelectedOrgId,
  getSelectedFcYearId,
  changeFcYear,
} from "../../thunks/user-management";
import { addAppTab, decrementPendingRequests, incrementPendingRequests, useMouseTrap } from "../../utils";
import { CustomModal, DataTable } from "../utils/custom-elements/Index";
import { useMemo } from "react";
import { useState } from "react";
import { set } from "automate-redux";
import BrandName from "../../assets/name-and-tagline.png";
import UserIcon from "../../assets/user icon.png";

const getFcYearName = (fromDate, toDate) => {
  const fromYear = new Date(fromDate);
  const toYear = new Date(toDate);
  const yearName = `${fromYear.getFullYear().toString()} - ${toYear
    .getFullYear()
    .toString()
    .slice(2, 4)}`;
  return yearName;
};

const getFcYearFullName = (fromDate, toDate) => {
  const fromYear = new Date(fromDate);
  const toYear = new Date(toDate);
  const yearName = `${fromYear.getFullYear().toString()} - ${toYear.getFullYear().toString()}`;
  return yearName;
};

const getFcYearsList = (orgs) => {
  const fcYears = [];
  orgs?.forEach((obj) =>
    obj.fc_years?.forEach((obj) => fcYears.push(getFcYearFullName(obj.date1, obj.date2)))
  );
  const uniqueFcYears = Array.from(new Set(fcYears)).sort((a, b) => (a > b ? -1 : 1));
  const data = uniqueFcYears.map((yearName) => {
    const [fromYear, toYear] = yearName.split(" - ");
    return { desc: yearName, fromDate: `01-04-${fromYear}`, toDate: `31-03-${toYear}` };
  });
  return data;
};

const getOrgsList = (orgs, fcYearName) => {
  if (!fcYearName) {
    return [];
  }

  const [fromYear, toYear] = fcYearName.split(" - ");
  const data = [];
  orgs?.forEach((org) =>
    org.fc_years?.forEach((fcYear) => {
      if (fcYear.date1.includes(fromYear) && fcYear.date2.includes(toYear)) {
        data.push({ name: org.name, orgId: org.id, fyId: fcYear.id });
      }
    })
  );
  data.sort((a, b) => (a.name < b.name ? -1 : 1));
  return data;
};

export const CompanySelectorModal = ({ handleCancel, zIndex }) => {
  const dispatch = useDispatch();
  const orgs = useSelector((state) => getOrganizations(state));
  const selectedOrgId = useSelector(state => getSelectedOrgId(state));

  const [selectedTempFcYearName, setSelectedTempFcYearName] = useState();
  const [yearSelectorModalVisbke, setYearSelectorModalVisible] = useState(true);
  const [companySelectorModalVisible, setCompanySelectorModalVisible] = useState(false);

  // Years list for the year selector modal
  const yearsList = useMemo(() => getFcYearsList(orgs), [orgs]);

  // Companies list for the company selector modal
  const orgsList = useMemo(
    () => getOrgsList(orgs, selectedTempFcYearName),
    [orgs, selectedTempFcYearName]
  );
  const initialOrgIndex = Math.max(orgsList.findIndex(obj => obj.orgId === selectedOrgId), 0);

  const handleSelectYear = (fcYearName) => {
    if (orgs.length === 1) {
      const orgsList = getOrgsList(orgs, fcYearName);
      dispatch(changeFcYear(orgsList[0].orgId, orgsList[0].fyId));
      handleCancel();
      return;
    }
    setSelectedTempFcYearName(fcYearName);
    setYearSelectorModalVisible(false);
    setTimeout(() => setCompanySelectorModalVisible(true), 100);
  };

  const handleSelectOrg = (orgId, fcYearId) => {
    dispatch(changeFcYear(orgId, fcYearId));
    handleCancel();
  };

  if (yearSelectorModalVisbke) {
    const columns = [
      {
        id: "desc",
        title: "Year Description",
        isNavigable: true,
        align: "center",
      },
      {
        id: "fromDate",
        title: "From Date",
        isNavigable: true,
        width: 150,
        suppressSizeToFit: true,
        align: "center",
      },
      {
        id: "toDate",
        title: "To Date",
        isNavigable: true,
        width: 150,
        suppressSizeToFit: true,
        align: "center",
      },
    ];

    return (
      <CustomModal onCancel={handleCancel} title="Select a financial year" width="600px" zIndex={zIndex || 1000}>
        <DataTable
          gridOptions={{ rowStyle: { cursor: "pointer" } }}
          enableSearch={false}
          columns={columns}
          data={yearsList}
          onEnter={(record) => handleSelectYear(record.desc)}
        />
      </CustomModal>
    );
  }
  if (companySelectorModalVisible) {
    const columns = [
      {
        id: "sr",
        title: "Sr.",
        width: 45,
        suppressSizeToFit: true,
        isNavigable: false,
        valueGetter: (params) => params.node.rowIndex + 1,
        align: "center",
      },
      {
        id: "name",
        title: "Company Name",
        isNavigable: true,
      },
    ];
    return (
      <CustomModal onCancel={handleCancel} width="610px" title="Select a company" zIndex={zIndex || 1000}>
        <div style={{ height: 576, overflowY: "auto" }}>
          <DataTable
            initialColId="name"
            initialRowIndex={initialOrgIndex}
            gridOptions={{ rowStyle: { cursor: "pointer" } }}
            containerStyle={{ width: "100%", height: "100%" }}
            columns={columns}
            data={orgsList}
            onEnter={(record) => handleSelectOrg(record.orgId, record.fyId)}
          />
        </div>
      </CustomModal>
    );
  }

  return null;
};

const SelectedCompanyButton = ({ handleClick, selectedOrgName, selectedFcYearName }) => {
  return (
    <div
      style={{
        background: "white",
        height: 28,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 5,
        cursor: "pointer",
        padding: "0px 12px"
      }}
      onClick={handleClick}
    >
      {selectedOrgName && selectedFcYearName && (
        <>
          <Tooltip title={selectedOrgName}>
            <Typography.Text style={{ maxWidth: 200, }} ellipsis>
              {selectedOrgName}
            </Typography.Text>
          </Tooltip>
          <span style={{ marginLeft: 4 }}>({selectedFcYearName})</span>
        </>
      )}
    </div>
  );
};

const UserAvatar = () => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const dispatch = useDispatch();
  const handleClickLogout = async () => {
    try {
      incrementPendingRequests();
      await dispatch(logout());
    } finally {
      decrementPendingRequests();
    }
  };
  const handleClickLockScreen = () => dispatch(set("screenLockPassed", false));
  return (
    <Badge offset={[-5, 5]}>
      <Popover
        overlayClassName="topbar-user-popover"
        placement="bottomRight"
        overlayInnerStyle={{ padding: 0 }}
        content={
          <AvatarPopoverContent
            handleClickLogout={handleClickLogout}
            handleClickLockScreen={handleClickLockScreen}
            hidePopover={() => setPopoverVisible(false)}
          />
        }
        onOpenChange={(visible) => setPopoverVisible(visible)}
        open={popoverVisible}
        trigger="click"
      >
        <img src={UserIcon} alt="User" style={{ width: 28, height: 28, cursor: "pointer", borderRadius: "50%" }} />
      </Popover>
    </Badge>
  );
};

const AvatarPopoverContent = ({ handleClickLogout, handleClickLockScreen, hidePopover }) => (
  <Menu
    onClick={hidePopover}
    style={{ lineHeight: "initial" }}
    items={[
      { label: "Your Profile", onClick: () => addAppTab("/profile", "Profile") },
      { label: "Your Companies", onClick: () => addAppTab("/companies", "Companies") },
      { type: "divider" },
      { label: "Lock Screen", onClick: handleClickLockScreen },
      { label: "Logout", onClick: handleClickLogout },
    ]}
  />
);

export default () => {
  const [companySelectorPopupVisible, setCompanySelectorPopupVisible] = useState(false);
  const selectedOrgId = useSelector((state) => getSelectedOrgId(state));
  const selectedFcYearId = useSelector((state) => getSelectedFcYearId(state));
  const orgs = useSelector((state) => getOrganizations(state));
  const selectedOrg = orgs.find((obj) => obj.id === selectedOrgId);
  const selectedOrgName = selectedOrg?.name;
  const selectedFcYear = selectedOrg?.fc_years.find((obj) => obj.id === selectedFcYearId);
  const selectedFcYearName = getFcYearName(selectedFcYear?.date1, selectedFcYear?.date2);
  useMouseTrap(
    { current: document.body },
    {
      "alt+f1": (e) => {
        e.preventDefault();
        setCompanySelectorPopupVisible(true);
      },
    }
  );
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        borderBottom: "1px solid #ccc"
      }}
    >
      <div className="topbar">
        <img src={BrandName} alt="Simple Hisaab" height={28} />
        <Space>
          <SelectedCompanyButton
            selectedOrgName={selectedOrgName}
            selectedFcYearName={selectedFcYearName}
            handleClick={() => setCompanySelectorPopupVisible(true)}
          />
          <UserAvatar />
        </Space>
      </div>
      {companySelectorPopupVisible && (
        <CompanySelectorModal handleCancel={() => setCompanySelectorPopupVisible(false)} />
      )}
    </div>
  );
};
