import { get, set } from "automate-redux";
import printJS from "print-js";
import { getOrgDetails, getSelectedFcYearDetails, getSelectedFcYearId, getSelectedOrgId } from "./user-management";
import store from "../store";
import { axios } from "../client";
import {
  extractNumberFromString,
  getLastTimestampOfRecords,
  mergeExistingAndUpdatedRecords,
  notifyError,
  notifySuccess,
  printOrSaveBlobFromServer,
  saveBlobFromServer,
  uploadFile,
  uploadFiles,
} from "../utils";
import { mergeExistingAndUpdatedMastersFromServer } from "./master";
import { trnTypes } from "../constants";
import { getSettingRecordDetails } from "./settings";
import { compile as handleBarsCompile } from "handlebars";

const setTransactions = (trnType, data) => set(`transactions.${trnType}.data`, data);
const setOpeningTransactions = (trnType, data) => set(`openingTransactions.${trnType}.data`, data);

export const setTransactionsPeriodFilter = (trnType, period) => {
  const currentPeriodFilter = getTransactionsPeriodFilter(store.getState(), trnType);
  if (JSON.stringify(currentPeriodFilter) !== JSON.stringify(period)) {
    return set(`transactions.${trnType}`, { period });
  }
  return { type: "noop" };
};

export const loadTransactions = (trnType) => async (dispatch, getState) => {
  const state = getState();
  const { fromDate, toDate } = getTransactionsPeriodFilter(state, trnType);
  const existingTransactions = getTransactions(state, trnType);

  const queryParams = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    type: trnType,
    from_date: fromDate,
    to_date: toDate,
    last_timestamp: getLastTimestampOfRecords(existingTransactions),
  };

  const response = await axios.get("/v1/getTransactions", { params: queryParams });
  if (response.status !== 200) {
    throw response.data;
  }

  const updatedTransactions = response.data;
  const finalTransactions = mergeExistingAndUpdatedRecords(
    existingTransactions,
    updatedTransactions
  );
  const tableColumns = getSettingRecordDetails(state, "DATA_ENTRY", trnType)?.listTableColumns || [];
  const firstColId = tableColumns[0]?.id;
  const sortByVouno = firstColId === "vouno";
  const sortByCreatedAt = [trnTypes.AGENCY_PURCHASE, trnTypes.AGENCY_PAYMENT].includes(trnType);
  if (sortByCreatedAt) {
    finalTransactions.sort((a, b) => a.created_at > b.created_at ? -1 : 1);
  } else if (sortByVouno) {
    finalTransactions.sort((a, b) => {
      const seqNoA = extractNumberFromString(a.vouno || a.refno);
      const seqNoB = extractNumberFromString(b.vouno || b.refno);
      return seqNoA > seqNoB ? -1 : 1;
    });
  } else {
    finalTransactions.sort((a, b) => {
      if (a.date !== b.date) {
        return a.date > b.date ? -1 : 1;
      }

      const seqNoA = extractNumberFromString(a.refno);
      const seqNoB = extractNumberFromString(b.refno);
      return seqNoA > seqNoB ? -1 : 1;
    });
  }

  dispatch(setTransactions(trnType, finalTransactions));
};

export const invalidateTransactions = (trnType) => (dispatch) => {
  dispatch(set(`transactions.${trnType}.data`, []))
}

export const loadOpeningTransactions = (trnType, columns) => async (dispatch, getState) => {
  const state = getState();
  const fcYearDetails = getSelectedFcYearDetails(state);
  const existingTransactions = getOpeningTransactions(state, trnType);

  const queryParams = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    type: trnType,
    from_date: "1970-01-01",
    to_date: fcYearDetails.date2,
    last_timestamp: getLastTimestampOfRecords(existingTransactions),
    opening: true,
    columns,
  };

  const response = await axios.get("/v1/getTransactions", { params: queryParams });
  if (response.status !== 200) {
    throw response.data;
  }

  const updatedTransactions = response.data;
  const finalTransactions = mergeExistingAndUpdatedRecords(
    existingTransactions,
    updatedTransactions
  );
  dispatch(setOpeningTransactions(trnType, finalTransactions));
};

export const fetchTransaction = async (id) => {
  const state = store.getState();

  const params = {
    org_id: getSelectedOrgId(state),
    id,
  };
  const { status, data } = await axios.get("/v1/getTransaction", { params });
  if (status !== 200) {
    throw data;
  }

  return data;
};

export const fetchBookCurrentSequenceNo = async (bookId, trnType, orgId, fyId) => {
  const state = store.getState();

  const params = {
    org_id: orgId || getSelectedOrgId(state),
    fy_id: fyId || getSelectedFcYearId(state),
    book_id: bookId,
    type: trnType,
  };

  const { status, data } = await axios.get("/v1/getCurrentSequenceNo", { params });
  if (status !== 200) {
    throw data;
  }

  return Promise.resolve(data);
};

export const fetchNextVoucherNo = async (trnType, bookId, orgId, fyId, field) => {
  const state = store.getState();

  const params = {
    org_id: orgId || getSelectedOrgId(state),
    fy_id: fyId || getSelectedFcYearId(state),
    book_id: bookId,
    type: trnType,
    field,
  };

  const { status, data } = await axios.get("/v1/getNextVoucherNo", { params });
  if (status !== 200) {
    throw data;
  }

  let voucherNoField = "refno";
  switch (trnType) {
    case trnTypes.PURCHASE:
    case trnTypes.PURCHASE_CHALLAN:
    case trnTypes.JOB_WORK_RECEIVE:
    case trnTypes.MILL_RECEIVE:
    case trnTypes.AGENCY_PURCHASE:
    case trnTypes.AGENCY_PURCHASE_ORDER:
    case trnTypes.AGENCY_PURCHASE_RETURN:
      voucherNoField = "vouno";
      break;
  }

  return Promise.resolve([data, voucherNoField]);
};

export const fetchTrnTypeCurrentSequenceNo = async (trnType, orgId, fyId) => {
  const state = store.getState();

  const params = {
    org_id: orgId || getSelectedOrgId(state),
    fy_id: fyId || getSelectedFcYearId(state),
    type: trnType,
  };

  const { status, data } = await axios.get("/v1/getCurrentSequenceNo", { params });
  if (status !== 200) {
    throw data;
  }

  return Promise.resolve(data);
};

export const fetchNextChequeNo = async (bookId) => {
  const state = store.getState();

  const params = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    book_id: bookId,
  };

  const { status, data } = await axios.get("/v1/getNextChequeNo", { params });
  if (status !== 200) {
    throw data;
  }

  return Promise.resolve(data);
};

export const checkIfReceiptPaymentIsDuplicate = async (orgId, fyId, type, bookId, partyId, date, amt, chqno) => {
  const state = store.getState();

  const params = {
    org_id: orgId || getSelectedOrgId(state),
    fy_id: fyId || getSelectedFcYearId(state),
    type,
    book_id: bookId,
    party_id: partyId,
    date,
    amt,
    chqno,
  };

  const { status, data } = await axios.get("/v1/checkIfReceiptPaymentIsDuplicate", { params });
  if (status !== 200) {
    throw data;
  }

  return Promise.resolve(data);
};

export const checkIfRefnoIsDuplicate = async (orgId, fyId, trnType, refno, bookId, partyId, id) => {
  const state = store.getState();

  const params = {
    org_id: orgId || getSelectedOrgId(state),
    fy_id: fyId || getSelectedFcYearId(state),
    refno,
    type: trnType,
    book_id: bookId,
    party_id: partyId,
    id,
  };

  const { status, data } = await axios.get("/v1/checkIfRefnoIsDuplicate", { params });
  if (status !== 200) {
    throw data;
  }

  return Promise.resolve(data);
};

export const checkIfLrnoIsDuplicate = async (orgId, fyId, lrno, id) => {
  const state = store.getState();

  const params = {
    org_id: orgId || getSelectedOrgId(state),
    fy_id: fyId || getSelectedFcYearId(state),
    lrno,
    id,
  };

  const { status, data } = await axios.get("/v1/checkIfLrnoIsDuplicate", { params });
  if (status !== 200) {
    throw data;
  }

  return Promise.resolve(data);
};

export const fetchPartiesByRefno = async (trnTypes, refno) => {
  const state = store.getState();

  const params = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    types: trnTypes,
    refno,
  };

  const { status, data } = await axios.get("/v1/getPartiesByRefno", { params });
  if (status !== 200) {
    throw data;
  }

  return Promise.resolve(data);
};

export const getTrnDetailsForChequeReturnEntry = async (orgId, bookId, partyId, date, chqno) => {
  const state = store.getState();

  const params = {
    org_id: orgId || getSelectedOrgId(state),
    book_id: bookId,
    party_id: partyId,
    date,
    chqno
  };

  const { status, data } = await axios.get("/v1/getTrnDetailsForChequeReturnEntry", { params });
  if (status !== 200) {
    throw data;
  }

  return Promise.resolve(data);
};

export const fetchChequePrintingDetails = async (trnId) => {
  const state = store.getState();

  const params = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    id: trnId,
  };

  const { status, data } = await axios.get("/v1/getChequePrintingDetails", { params });
  if (status !== 200) {
    throw data;
  }

  return Promise.resolve(data);
};

export const fetchOutstandingTransactions = async (trnType, trnNature, trnId, partyId, orgId, fyId) => {
  const state = store.getState();
  const params = {
    org_id: orgId || getSelectedOrgId(state),
    fy_id: fyId || getSelectedFcYearId(state),
    trn_type: trnType,
    trn_nature: trnNature,
    trn_id: trnId,
    party_id: partyId,
  };
  const { status, data } = await axios.get("/v1/getOutstandingTransactions", { params });
  if (status !== 200) {
    throw data;
  }

  data.sort((a, b) => {
    if (a.date !== b.date) {
      return a.date < b.date ? -1 : 1;
    }
    if (a.refno?.length !== b.refno?.length) {
      return a.refno?.length < b.refno?.length ? -1 : 1;
    }

    const seqNoA = extractNumberFromString(a.refno);
    const seqNoB = extractNumberFromString(b.refno);
    return seqNoA < seqNoB ? -1 : 1;
  });
  return data;
};

export const fetchOutstandingTransactionsForContraRecon = async (partyId) => {
  const state = store.getState();
  const params = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    party_id: partyId,
  };
  const { status, data } = await axios.get("/v1/getOutstandingTransactionsForContraRecon", { params });
  if (status !== 200) {
    throw data;
  }

  data.sort((a, b) => {
    if (a.date !== b.date) {
      return a.date < b.date ? -1 : 1;
    }
    if (a.refno?.length !== b.refno?.length) {
      return a.refno?.length < b.refno?.length ? -1 : 1;
    }

    const seqNoA = extractNumberFromString(a.refno);
    const seqNoB = extractNumberFromString(b.refno);
    return seqNoA < seqNoB ? -1 : 1;
  });
  return data;
};

export const fetchOutstandingAgencyTransactions = async (trnTypes, trnId, customerId, supplierId, orgId, fyId) => {
  const state = store.getState();
  const params = {
    org_id: orgId || getSelectedOrgId(state),
    fy_id: fyId || getSelectedFcYearId(state),
    types: trnTypes,
    trn_id: trnId,
    cust_id: customerId,
    supp_id: supplierId
  };
  const { status, data } = await axios.get("/v1/getOutstandingAgencyTransactions", { params });
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const fetchOutstandingOrderItems = async (partyId, customerId, supplierId) => {
  const state = store.getState();
  const params = {
    org_id: getSelectedOrgId(state),
    party_id: partyId,
    cust_id: customerId,
    supp_id: supplierId,
  };
  const { status, data } = await axios.get("/v1/getOutstandingOrderItems", { params });
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const fetchOutstandingPurchaseItems = async () => {
  const state = store.getState();
  const params = {
    org_id: getSelectedOrgId(state),
  };
  const { status, data } = await axios.get("/v1/getOutstandingPurchaseItemsForSale", { params });
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const updatePurchaseReconStatus = async (ids, cleared) => {
  const state = store.getState();
  const body = {
    org_id: getSelectedOrgId(state),
    ids,
    cleared
  };
  const { status, data } = await axios.post("/v1/updatePurchaseReconStatus", body);
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const fetchOrderItemsStatus = async (orderId) => {
  const state = store.getState();
  const params = {
    org_id: getSelectedOrgId(state),
    order_ids: [orderId],
    status: "All",
  };
  const { status, data } = await axios.get("/v1/getOutstandingOrderItems", { params });
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const updateOrderStatus = async (ids, cleared) => {
  const state = store.getState();
  const body = {
    org_id: getSelectedOrgId(state),
    ids,
    cleared
  };
  const { status, data } = await axios.post("/v1/updateOrderStatus", body);
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const updateOrderItemStatus = async (trnId, items) => {
  const state = store.getState();
  const body = {
    org_id: getSelectedOrgId(state),
    trn_id: trnId,
    data: items
  };
  const { status, data } = await axios.post("/v1/updateOrderItemStatus", body);
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const fetchOutstandingStockItemsByParty =
  async (orgId, fyId, options = {}) => {
    const state = store.getState();
    const params = {
      org_id: orgId || getSelectedOrgId(state),
      fy_id: fyId || getSelectedFcYearId(state),
      ...options
    };

    const { status, data } = await axios.get("/v1/getOutstandingStockItems", { params });
    if (status !== 200) {
      throw data;
    }
    return data;
  };

export const fetchOutstandingStockItems = async (options) => {
  const state = store.getState();
  const params = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    ...options
  };

  const { status, data } = await axios.get("/v1/getOutstandingStockItems", { params });
  if (status !== 200) {
    throw data;
  }
  return data;
}

export const fetchOutstandingStockItemPcs = async (stockIds, removeUsedQtyField, trnType, srcTrnItemIds) => {
  const state = store.getState();
  const params = {
    org_id: getSelectedOrgId(state),
    stk_ids: stockIds,
    trn_type: trnType,
    src_trn_itm_ids: srcTrnItemIds,
  };

  const { status, data } = await axios.get("/v1/getOutstandingStockItemPcs", { params });
  if (status !== 200) {
    throw data;
  }

  if (removeUsedQtyField) {
    data?.forEach(obj => {
      delete obj["used_qty"]
    })
  }

  return data;
};

export const fetchSrcTrnItemsHistory = async (srcTrnItemIds) => {
  const state = store.getState();
  const params = { org_id: getSelectedOrgId(state), src_trn_itm_ids: srcTrnItemIds };
  const { status, data } = await axios.get("/v1/getSrcTrnItemsHistory", { params });
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const fetchItemStockAtWarehouse = async (itemId) => {
  const state = store.getState();
  const params = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    itm_id: itemId,
  };
  const { status, data } = await axios.get("/v1/getItemStockAtWarehouse", { params });
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const createTransaction = (values, files) => async (dispatch, getState) => {
  const state = getState();

  const trnData = {
    ...values,
    amt_recon: values.amt_recon?.map(({
      trn_to,
      rec_amt,
      disc_per,
      disc_amt,
      rd,
      rd_amt,
      claim_amt,
      int_amt,
      rg_amt,
      lrno,
      lrdate,
      trans_id,
      tds_amt,
      adj_ac,
      adj_amt,
      remarks,
      recon_amt }) => ({
        trn_to,
        rec_amt,
        disc_per,
        disc_amt,
        rd,
        rd_amt,
        claim_amt,
        int_amt,
        rg_amt,
        lrno,
        lrdate,
        trans_id,
        tds_amt,
        adj_ac,
        adj_amt,
        remarks,
        recon_amt
      })),
  };
  const bodyData = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    data: JSON.stringify(trnData),
    masters: JSON.stringify(getMastersRequest(state, ["ACM", "ITM"])),
  };

  const data = await uploadFiles("/v1/createTransaction", files, bodyData)

  dispatch(mergeExistingAndUpdatedMastersFromServer(data.updatedMasters));
  return Promise.resolve(data.trnDetails);
};

export const createTransactionsInBulk = async (reqs, orgId, fyId) => {
  const state = store.getState();

  const request = {
    org_id: orgId || getSelectedOrgId(state),
    fy_id: fyId || getSelectedFcYearId(state),
    reqs,
    masters: JSON.stringify(getMastersRequest(state, ["ACM"])),
  };

  const { status, data } = await axios.post("/v1/createTransactionsInBulk", request);
  if (status !== 200) {
    throw data;
  }

  store.dispatch(mergeExistingAndUpdatedMastersFromServer(data.updatedMasters));
  return Promise.resolve(data.trnIds);
};

export const createAgencyDispatchLetters = async (reqs) => {
  const state = store.getState();

  const request = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    data: reqs,
  };

  const { status, data } = await axios.post("/v1/createAgencyDispatchLetters", request);
  if (status !== 200) {
    throw data;
  }

  return Promise.resolve(data);
};

export const deleteAgencyDispatchLetters = async (ids) => {
  const state = store.getState();

  const request = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    ids,
  };

  const { status, data } = await axios.post("/v1/deleteAgencyDispatchLetters", request);
  if (status !== 200) {
    throw data;
  }

  return Promise.resolve(data);
};

export const getExistingChallanNosAgainstPurchaseBill = async (againstTrnId) => {
  const state = store.getState();

  const params = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    against: againstTrnId,
  };

  const { status, data } = await axios.get("/v1/getExistingChallanNosAgainstPurchaseBill", {
    params,
  });
  if (status !== 200) {
    throw data;
  }
  return Promise.resolve(data);
};

export const createMillIssueTransactionsFromPurchase =
  (orgId, fyId, values, againstTrnId) => async (dispatch, getState) => {
    const state = getState();

    const request = {
      org_id: orgId || getSelectedOrgId(state),
      fy_id: fyId || getSelectedFcYearId(state),
      data: values,
      against: againstTrnId,
      masters: getMastersRequest(state, ["ACM", "ITM"]),
    };

    const { status, data } = await axios.post(
      "/v1/createMillIssueTransactionsFromPurchase",
      request
    );
    if (status !== 200) {
      throw data;
    }

    dispatch(mergeExistingAndUpdatedMastersFromServer(data.updatedMasters));
    return Promise.resolve(data.trnIdsCreated);
  };
export const updateTransaction = (id, values, files) => async (dispatch, getState) => {
  const state = getState();

  const trnData = {
    ...values,
    amt_recon: values.amt_recon?.map(({
      id,
      trn_to,
      rec_amt,
      disc_per,
      disc_amt,
      rd,
      rd_amt,
      claim_amt,
      int_amt,
      rg_amt,
      lrno,
      lrdate,
      trans_id,
      tds_amt,
      adj_ac,
      adj_amt,
      remarks,
      recon_amt }) => ({
        id,
        trn_to,
        rec_amt,
        disc_per,
        disc_amt,
        rd,
        rd_amt,
        claim_amt,
        int_amt,
        rg_amt,
        lrno,
        lrdate,
        trans_id,
        tds_amt,
        adj_ac,
        adj_amt,
        remarks,
        recon_amt
      })),
  }
  const bodyData = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    id,
    data: JSON.stringify(trnData),
    masters: JSON.stringify(getMastersRequest(state, ["ACM", "ITM"])),
  };

  const data = await uploadFiles("/v1/updateTransaction", files, bodyData)

  dispatch(mergeExistingAndUpdatedMastersFromServer(data.updatedMasters));

  return Promise.resolve();
};

const getMastersRequest = (state, masterTypes) => {
  const oldMasters = state.masters ?? {};
  const mastersRequest = masterTypes.map((type) => {
    const master = oldMasters[type] ?? {};
    const obj = { type };
    if (master.fetchedOnce) {
      obj.last_timestamp = getLastTimestampOfRecords(master.data ?? []);
    }
    return obj;
  });
  return mastersRequest;
};

export const deleteTransactions = (trnType, ids, pin) => async (dispatch, getState) => {
  const state = getState();
  const request = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    trn_type: trnType,
    ids,
    pin,
    masters: getMastersRequest(state, ["ACM"])
  };

  const { status, data } = await axios.post("/v1/deleteTransactions", request);
  if (status !== 200) {
    throw data;
  }

  const transactions = getTransactions(state, trnType);
  const newTransactions = transactions.filter((obj) => ids.findIndex((id) => obj.id === id) === -1);
  const openingTransactions = getOpeningTransactions(state, trnType);
  const newOpeningTransactions = openingTransactions.filter(
    (obj) => ids.findIndex((id) => obj.id === id) === -1
  );
  const tableColumns = getSettingRecordDetails(state, "DATA_ENTRY", trnType)?.listTableColumns || [];
  const firstColId = tableColumns[0]?.id;
  const sortByVouno = firstColId === "vouno";
  const sortByCreatedAt = [trnTypes.AGENCY_PURCHASE, trnTypes.AGENCY_PAYMENT].includes(trnType);
  if (sortByCreatedAt) {
    newTransactions.sort((a, b) => a.created_at > b.created_at ? -1 : 1);
  } else if (sortByVouno) {
    newTransactions.sort((a, b) => {
      const seqNoA = extractNumberFromString(a.vouno || a.refno);
      const seqNoB = extractNumberFromString(b.vouno || b.refno);
      return seqNoA > seqNoB ? -1 : 1;
    });
  } else {
    newTransactions.sort((a, b) => {
      if (a.date !== b.date) {
        return a.date > b.date ? -1 : 1;
      }

      const seqNoA = extractNumberFromString(a.refno);
      const seqNoB = extractNumberFromString(b.refno);
      return seqNoA > seqNoB ? -1 : 1;
    });
  }
  dispatch(setTransactions(trnType, newTransactions));
  dispatch(setOpeningTransactions(trnType, newOpeningTransactions));
  dispatch(mergeExistingAndUpdatedMastersFromServer(data.updatedMasters));
};

export const deleteOpeningTransaction = (trnType, id) => async (dispatch, getState) => {
  const state = getState();
  const request = { id, org_id: getSelectedOrgId(state), fy_id: getSelectedFcYearId(state) };

  const { status, data } = await axios.post("/v1/deleteTransaction", request);
  if (status !== 200) {
    throw data;
  }

  const openingTransactions = getOpeningTransactions(state, trnType);
  const newOpeningTransactions = openingTransactions.filter((obj) => obj.id !== id);
  dispatch(setOpeningTransactions(trnType, newOpeningTransactions));
};

export const shiftTrnsToAnotherOrg = async (ids, orgIdTo, fyIdTo) => {
  const state = store.getState();
  const request = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    ids,
    org_id_to: orgIdTo,
    fy_id_to: fyIdTo,
  };

  const { status, data } = await axios.post("/v1/shiftTrnsToAnotherOrg", request);
  if (status !== 200) {
    throw data;
  }
};

export const getTransactionsPeriodFilter = (state, trnType) => {
  const periodFilter = get(state, `transactions.${trnType}.period`);
  if (!periodFilter) {
    const fcYearDetails = getSelectedFcYearDetails(state);
    return {
      periodType: "Current Year",
      fromDate: fcYearDetails.date1,
      toDate: fcYearDetails.date2,
    };
  }

  return periodFilter;
};

export const getTransactions = (state, trnType) => get(state, `transactions.${trnType}.data`, []);
export const getOpeningTransactions = (state, trnType) =>
  get(state, `openingTransactions.${trnType}.data`, []);

export const getTransaction = (state, id) => get(state, `transactionsMap.${id}`);
export const getOutstandingTransactions = (state) => get(state, "outstandingTransactions", []);

export const returnCheque = (trnType, trnId, date, remarks) => async (dispatch, getState) => {
  const state = getState();
  const req = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    id: trnId,
    date,
    remarks,
  };

  const { status, data } = await axios.post("/v1/returnCheque", req);
  if (status !== 200) {
    throw data;
  }

  await dispatch(loadTransactions(trnType));
};

export const printTrnsPDF = async (trnIds, trnType, docType, options, sortBySelectionIndex) => {
  return new Promise((resolve, reject) => {
    const state = store.getState();
    const data = {
      org_id: getSelectedOrgId(state),
      fy_id: getSelectedFcYearId(state),
      options: {
        trnIds,
        trnType,
        docType,
        options,
        sortBySelectionIndex
      },
    };

    axios
      .post("/v1/downloadTrnsPdf", data, { responseType: "arraybuffer" })
      .then((response) => {
        if (response.status !== 200) {
          reject(response.data);
          return;
        }
        const pdfFile = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = window.URL.createObjectURL(pdfFile);
        printJS({
          printable: pdfUrl,
          onPrintDialogClose: () => resolve(),
          onError: (error) => reject(error),
        });
      })
      .catch((error) => reject(error));
  });
};

export const downloadTrnsPDF = async (trnIds, trnType, docType, options) => {
  const state = store.getState();
  const data = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    options: {
      trnIds,
      trnType,
      docType,
      options,
    },
  };
  const response = await axios.post("/v1/downloadTrnsPdf", data, {
    responseType: "arraybuffer",
  });
  if (response.status !== 200) {
    console.log("Error", response.data);
    return;
  }
  printOrSaveBlobFromServer(response, "save");
};



export const whatsappTrns = async (trnIds, options) => {
  const state = store.getState();
  const data = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    trn_ids: trnIds,
    options,
  };
  const response = await axios.post("/v1/whatsappTrns", data);
  if (response.status !== 200) {
    throw response.data;
  }
};

export const emailTrns = async (trnIds, options) => {
  const state = store.getState();
  const data = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    trn_ids: trnIds,
    options,
  };
  const response = await axios.post("/v1/emailTrns", data);
  if (response.status !== 200) {
    throw response.data;
  }
};

export const fetchUnPaidCommList = async (orgId, fyId, brokerId, from, to) => {
  const state = store.getState();
  const params = {
    org_id: orgId || getSelectedOrgId(state),
    fy_id: fyId || getSelectedFcYearId(state),
    broker_id: brokerId,
    from,
    to,
  };
  const { status, data } = await axios.get("/v1/getUnPaidCommList", { params });
  if (status !== 200) {
    throw data;
  }

  return data;
};

export const fetchUnBilledAgencyPaymentVouchers = async (partyId, from, to, vouOrgId, billingStatus = "Unbilled") => {
  const state = store.getState();
  const params = {
    org_id: getSelectedOrgId(state),
    party_id: partyId,
    from,
    to,
    vou_org_id: vouOrgId,
    status: billingStatus
  };
  const { status, data } = await axios.get("/v1/getUnBilledAgencyPaymentVouchers", { params });
  if (status !== 200) {
    throw data;
  }

  return data;
};

export const fetchAgencyDispatchLetters = async (letterStatus = "Pending") => {
  const state = store.getState();
  const params = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    status: letterStatus
  };
  const { status, data } = await axios.get("/v1/getAgencyDispatchLetters", { params });
  if (status !== 200) {
    throw data;
  }

  return data;
};

export const printAgencyDispatchLetters = async (ids) => {
  return new Promise((resolve, reject) => {
    const state = store.getState();
    const reqBody = {
      org_id: getSelectedOrgId(state),
      fy_id: getSelectedFcYearId(state),
      ids,
    };
    axios.post(
      "/v1/downloadAgencyDispatchLetters",
      reqBody,
      { responseType: "arraybuffer" }
    ).then(response => {
      if (response.status !== 200) {
        reject(response.data);
        return;
      }
      const pdfFile = new Blob([response.data], { type: "application/pdf" });
      const pdfUrl = window.URL.createObjectURL(pdfFile);
      printJS({
        printable: pdfUrl,
        onPrintDialogClose: () => resolve(),
        onError: (error) => reject(error),
      });
    }).catch(ex => reject(ex))
  })
};

export const fetchUnPaidJobReceiveTrnItems = async (orgId, partyId, from, to) => {
  const state = store.getState();
  const params = {
    org_id: orgId || getSelectedOrgId(state),
    party_id: partyId,
    from,
    to,
  };
  const { status, data } = await axios.get("/v1/getUnPaidJobReceiveTrnItems", { params });
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const fetchUnBilledJobOutwardTrnItems = async (orgId, partyId, from, to) => {
  const state = store.getState();
  const params = {
    org_id: orgId || getSelectedOrgId(state),
    party_id: partyId,
    from,
    to,
  };
  const { status, data } = await axios.get("/v1/getUnBilledJobOutwardTrnItems", { params });
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const fetchUnBilledChallanTrnItems = async (orgId, partyId, trnTypeFor, from, to) => {
  const state = store.getState();
  const params = {
    org_id: orgId || getSelectedOrgId(state),
    party_id: partyId,
    trn_type_for: trnTypeFor,
    from,
    to,
  };
  const { status, data } = await axios.get("/v1/getUnBilledChallanTrnItems", { params });
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const fetchUnPaidInterestList = async (partyId, from, to, days) => {
  const state = store.getState();
  const params = {
    org_id: getSelectedOrgId(state),
    party_id: partyId,
    days,
    from,
    to,
  };
  const { status, data } = await axios.get("/v1/getUnPaidInterestList", { params });
  if (status !== 200) {
    throw data;
  }

  return data;
};

export const assignLotNo = async (srcTrnItmId, lotNo) => {
  const state = store.getState();
  const reqBody = {
    org_id: getSelectedOrgId(state),
    src_trn_itm_id: srcTrnItmId,
    lotno: lotNo,
  };

  const { status, data } = await axios.post("/v1/assignLotNo", reqBody);
  if (status !== 200) {
    throw data;
  }
};

export const markTrnLedgerAck = async (trnId, ack) => {
  const state = store.getState();
  const reqBody = {
    org_id: getSelectedOrgId(state),
    trn_id: trnId,
    ack,
  };

  const { status, data } = await axios.post("/v1/markTrnLedgerAck", reqBody);
  if (status !== 200) {
    throw data;
  }
};

export const resetTrnLedgerAck = async (acmId, from, to) => {
  const state = store.getState();
  const reqBody = {
    org_id: getSelectedOrgId(state),
    acm_id: acmId,
    from,
    to,
  };

  const { status, data } = await axios.post("/v1/resetTrnLedgerAck", reqBody);
  if (status !== 200) {
    throw data;
  }
};

export const resetTrnsBankClearing = async (acmId, from, to) => {
  const state = store.getState();
  const reqBody = {
    org_id: getSelectedOrgId(state),
    acm_id: acmId,
    from,
    to,
  };

  const { status, data } = await axios.post("/v1/resetTrnsBankClearing", reqBody);
  if (status !== 200) {
    throw data;
  }
};

export const generateEinvoicesAndEwaybills = async (trnIds, mode) => {
  const state = store.getState();
  const reqBody = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    ids: trnIds,
    mode,
  };
  const { status, data } = await axios.post("/v1/generateEinvoicesAndEwaybills", reqBody);
  if (status !== 200) {
    throw data;
  }

  if (data.ewayBillsGenerated.length > 0) {
    const count = data.ewayBillsGenerated.length;
    notifySuccess(`Generated ${count} eway bill${count > 1 ? "s" : ""} successfully`);
  }

  if (data.einvoicesGenerated.length > 0) {
    const count = data.einvoicesGenerated.length;
    notifySuccess(`Generated ${count} e invoice${count > 1 ? "s" : ""} successfully`);
  }

  if (data.ewayBillsFailed.length > 0) {
    const count = data.ewayBillsFailed.length;
    const msgBillnoMap = data.ewayBillsFailed.reduce((acc, curr) => {
      const msg = curr.error?.results?.message || curr.error?.results?.errorMessage;
      if (!acc[msg]) {
        acc[msg] = [curr.docNo]
      } else {
        acc[msg].push(curr.docNo);
      }
      return acc;
    }, {});
    const errorMsg = `Error generating eway bill for ${count} doc${count > 1 ? "s" : ""
      } (${Object.entries(msgBillnoMap).map(([msg, billNos]) => `Bill No: ${billNos.join(", ")}, Error: ${msg}`).join(", ")})`;
    notifyError(errorMsg, "", data.ewayBillsFailed);
  }

  if (data.einvoicesFailed.length > 0) {
    const count = data.einvoicesFailed.length;
    const msgBillnoMap = data.einvoicesFailed.reduce((acc, curr) => {
      const msg = curr.error?.results?.message || curr.error?.results?.errorMessage;
      if (!acc[msg]) {
        acc[msg] = [curr.docNo]
      } else {
        acc[msg].push(curr.docNo);
      }
      return acc;
    }, {});
    const errorMsg = `Error generating e-invoice for ${count} doc${count > 1 ? "s" : ""
      } (${Object.entries(msgBillnoMap).map(([msg, billNos]) => `Bill No: ${billNos.join(", ")}, Error: ${msg}`).join(", ")})`;
    notifyError(errorMsg, "", data.einvoicesFailed);
  }

  return data;
};

export const generateEwaybillsJsonFile = async (trnIds) => {
  const state = store.getState();
  const reqBody = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    ids: trnIds,
  };
  const serverResponse = await axios.post("/v1/generateEwaybillsJsonFile", reqBody, { responseType: "arraybuffer" });
  if (serverResponse.status !== 200) {
    throw serverResponse.data;
  }

  saveBlobFromServer(serverResponse);
};

export const exportTrnsCsv = async (trnType, trnIds, format) => {
  const state = store.getState();
  const reqBody = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    trn_type: trnType,
    trn_ids: trnIds,
    format,
  };
  const serverResponse = await axios.post("/v1/exportTrnsCsv", reqBody, { responseType: "arraybuffer" });
  if (serverResponse.status !== 200) {
    throw serverResponse.data;
  }

  saveBlobFromServer(serverResponse);
};

export const importTrnsCsv = (trnType, format, formData = {}, file) => async (dispatch, getState) => {
  const state = getState();
  const reqBody = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    trn_type: trnType,
    format,
    data: JSON.stringify(formData),
  };
  const totalCreated = await uploadFile("/v1/importTrnsCsv", file, reqBody);
  await dispatch(loadTransactions(trnType));
  return totalCreated;
};

export const cancelEinvoiceAndEwaybill = async (trnId, reason, remark, cancelType) => {
  const state = store.getState();
  const req = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    id: trnId,
    reason,
    remark,
    cancel_type: cancelType,
  };

  const { status, data } = await axios.post("/v1/cancelEinvoiceAndEwaybill", req);
  if (status !== 200) {
    throw data;
  }
};

export const updateEwayBillTransporter = async (trnId, transId) => {
  const state = store.getState();
  const req = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    id: trnId,
    trans_id: transId,
  };

  const { status, data } = await axios.post("/v1/updateEwayBillTransporterId", req);
  if (status !== 200) {
    throw data;
  }
};

export const registerEinvoiceAndEwaybill = async (username, password) => {
  const state = store.getState();
  const req = {
    org_id: getSelectedOrgId(state),
    username,
    password,
  };

  const { status, data } = await axios.post("/v1/registerEinvoiceAndEwaybillCredentials", req);
  if (status !== 200) {
    throw data;
  }
};

export const updateBrokerInTransactions = async (options) => {
  const state = store.getState();
  const req = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    ...options,
  };
  const { status, data } = await axios.post("/v1/utilities/updateBrokerInTransactions", req);
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const updateAccount = async (options) => {
  const state = store.getState();
  const req = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    ...options,
  };
  const { status, data } = await axios.post("/v1/utilities/updateAccount", req);
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const updateItem = async (options) => {
  const state = store.getState();
  const req = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    ...options,
  };
  const { status, data } = await axios.post("/v1/utilities/updateItem", req);
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const updateItemScreen = async (options) => {
  const state = store.getState();
  const req = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    ...options,
  };
  const { status, data } = await axios.post("/v1/utilities/updateItemScreen", req);
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const bulkUpdateMasters = (type, ids, field, value) => async (dispatch, getState) => {
  const state = getState();
  const existingMasterRecords = state.masters?.[type]?.data;
  const req = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    type,
    ids,
    field,
    value,
    last_timestamp: getLastTimestampOfRecords(existingMasterRecords),
  };
  const { status, data } = await axios.post("/v1/utilities/bulkUpdateMasters", req);
  if (status !== 200) {
    throw data;
  }
  const newRecordsFromServer = data.data;
  dispatch(mergeExistingAndUpdatedMastersFromServer({ [type]: newRecordsFromServer }));
  return data;
};

export const updateCut = async (options) => {
  const state = store.getState();
  const req = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    ...options,
  };
  const { status, data } = await axios.post("/v1/utilities/updateCut", req);
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const updateWastage = async (options) => {
  const state = store.getState();
  const req = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    ...options,
  };
  const { status, data } = await axios.post("/v1/updateAutoCuttingTrnsInBulk", req);
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const fetchAutoCuttingCards = async () => {
  const state = store.getState();
  const params = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
  };
  const { status, data } = await axios.get("/v1/getAutoCuttingCardsList", { params });
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const reindexVoucherNo = async (trnType, bookId) => {
  const state = store.getState();
  const req = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    type: trnType,
    book_id: bookId
  };
  const { status, data } = await axios.post("/v1/utilities/reindexVoucherNo", req);
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const resaveTransactions = async (values) => {
  const state = store.getState();
  const req = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    ...values
  };
  const { status, data } = await axios.post("/v1/resaveTransactions", req);
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const checkData = async (type) => {
  const state = store.getState();
  const reqBody = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    type
  };
  const { status, data } = await axios.post("/v1/utilities/checkData", reqBody);
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const printOrSaveCheckData = async (type, action, fileType) => {
  const state = store.getState();
  const reqBody = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    type,
    file_type: fileType,
  };
  const response = await axios.post("/v1/utilities/downloadCheckData", reqBody, {
    responseType: "arraybuffer",
  });
  if (response.status !== 200) {
    console.log("Error", response.data);
    return;
  }
  printOrSaveBlobFromServer(response, action);
};

export const fetchPendingLRList = async (fromDate, toDate) => {
  const state = store.getState();
  const params = {
    org_id: getSelectedOrgId(state),
    from: fromDate,
    to: toDate,
  };
  const { status, data } = await axios.get("/v1/getPendingLRList", { params });
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const fetchPendingLotnoList = async (partyId) => {
  const state = store.getState();
  const params = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    party_id: partyId
  };
  const { status, data } = await axios.get("/v1/getPendingLotnoList", { params });
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const fetchPendingAmtReconList = async () => {
  const state = store.getState();
  const params = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
  };
  const { status, data } = await axios.get("/v1/getPendingAmtReconList", { params });
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const fetchPendingEwayBills = async (fromDate, toDate) => {
  const state = store.getState();
  const params = {
    org_id: getSelectedOrgId(state),
    from: fromDate,
    to: toDate,
  };
  const { status, data } = await axios.get("/v1/getPendingEwayBills", { params });
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const fetchPendingEinvoices = async (fromDate, toDate) => {
  const state = store.getState();
  const params = {
    org_id: getSelectedOrgId(state),
    from: fromDate,
    to: toDate,
  };
  const { status, data } = await axios.get("/v1/getPendingEinvoices", { params });
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const fetchMissingSeries = async () => {
  const state = store.getState();
  const params = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
  };
  const { status, data } = await axios.get("/v1/getMissingSeries", { params });
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const fetchListForCommStatusEntry = async (fromDate, toDate, commStatus) => {
  const state = store.getState();
  const params = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    from: fromDate,
    to: toDate,
    comm_status: commStatus
  };
  const { status, data } = await axios.get("/v1/getListForCommStatusEntry", { params });
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const updateTrnCommPaidStatus = async (trnIds, commPaid, commRemarks) => {
  const state = store.getState();
  const reqBody = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    ids: trnIds,
    comm_paid: commPaid,
    comm_remarks: commRemarks
  };
  const { status, data } = await axios.post("/v1/updateTrnCommPaidStatus", reqBody);
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const printOrSavePendingLRReport = async (fromDate, toDate, ids, action) => {
  const state = store.getState();
  const reqBody = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    from: fromDate,
    to: toDate,
    ids,
  };
  const response = await axios.post("/v1/downloadPendingLRReport", reqBody, { responseType: "arraybuffer" });
  if (response.status !== 200) {
    console.log("Error", response.data);
    return;
  }
  printOrSaveBlobFromServer(response, action);
};

export const updateLRDetailsInBulk = async (details) => {
  const state = store.getState();
  const reqBody = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    details,
  };
  const response = await axios.post("/v1/updateLRDetails", reqBody);
  if (response.status !== 200) {
    console.log("Error", response.data);
    return;
  }
};

export const updateLotnoDetailsInBulk = async (details) => {
  const state = store.getState();
  const reqBody = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    details,
  };
  const response = await axios.post("/v1/updateLotnoDetails", reqBody);
  if (response.status !== 200) {
    console.log("Error", response.data);
    return;
  }
};

export const fetchPartyReport = async (partyId) => {
  const state = store.getState();
  const params = {
    org_id: getSelectedOrgId(state),
    fy_id: getSelectedFcYearId(state),
    party_id: partyId
  };
  const { status, data } = await axios.get("/v1/getPartyReport", { params });
  if (status !== 200) {
    throw data;
  }
  return data;
};

export const generateNextVoucherNoFromCurrent = (currVoucherNo, bookSettings, formSettings, orgDetails) => {
  const state = store.getState();
  const prefixTemplate = bookSettings?.voucherNoPrefix || formSettings?.voucherNoPrefix || "";
  const suffixTemplate = bookSettings?.voucherNoSuffix || formSettings?.voucherNoSuffix || "";
  const width = bookSettings?.voucherNoWidth || formSettings?.voucherNoWidth;
  if (!orgDetails) {
    orgDetails = getOrgDetails(state);
  }
  const selectedFcYearDetails = getSelectedFcYearDetails(state);
  const prefixTemplateHasVariables = prefixTemplate.includes("{{");
  const suffixTemplateHasVariables = suffixTemplate.includes("{{");
  const templateData = {
    ACRONYM: orgDetails.acronym,
    FROM_YEAR: selectedFcYearDetails.date1.slice(0, 4),
    FROM_YEAR_SHORT: selectedFcYearDetails.date1.slice(2, 4),
    TO_YEAR: selectedFcYearDetails.date2.slice(0, 4),
    TO_YEAR_SHORT: selectedFcYearDetails.date2.slice(2, 4),
  }
  const prefix = prefixTemplateHasVariables ? handleBarsCompile(prefixTemplate)(templateData) : prefixTemplate;
  const suffix = suffixTemplateHasVariables ? handleBarsCompile(suffixTemplate)(templateData) : suffixTemplate;
  const currSeqNo = extractNumberFromString(currVoucherNo.replace(prefix, "").replace(suffix, ""));
  const nextSeqNo = currSeqNo + 1;
  let nextVoucherNo = String(nextSeqNo);
  if (width) {
    nextVoucherNo = nextVoucherNo.padStart(width, "0");
  }
  nextVoucherNo = `${prefix || ""}${nextVoucherNo}${suffix || ""}`;
  return nextVoucherNo
}

export const fetchBillsForCourierEntry = async (partyId, fromDate, toDate) => {
  const state = store.getState();
  const params = {
    org_id: getSelectedOrgId(state),
    party_id: partyId,
    from_date: fromDate,
    to_date: toDate,
  };

  const { status, data } = await axios.get("/v1/getBillsForCourierEntry", { params });
  if (status !== 200) {
    throw data;
  }

  return data;
};