import { nanoid } from "nanoid";
import { trnTypes } from "../../constants";
import store from "../../store";
import { getDaybooksForTrnType, getHSNDetails } from "../../thunks/master";
import { getDaybookSetting } from "../../thunks/settings";
import { addAppTab, decrementPendingRequests, incrementPendingRequests, notifyError, showYesNoConfimationModal } from "../../utils";
import { fetchNextVoucherNo, fetchOutstandingStockItemsByParty, fetchTransaction, getTransactions } from "../../thunks/transactions";
import { format } from "date-fns";

export const getItemPcsTableConfig = (trnType, outstandingStockItemPcs, initialValues, showRemarksColumn, remarksColumnTitle, forceAddMode) => {
  switch (trnType) {
    case trnTypes.PURCHASE:
    case trnTypes.JOB_WORK_INWARD:
      return {
        columns: [
          { id: "sr", name: "Sr", width: 25, readOnly: true },
          { id: "qty", name: "Mtr", width: 120, handleNextKey: true },
          { id: "cancel", width: 25, readOnly: true },
        ],
        allowAddRow: true,
      };

    case trnTypes.MILL_ISSUE:
    case trnTypes.PURCHASE_RETURN: {
      if ((outstandingStockItemPcs?.length || initialValues?.pcs?.length) && !forceAddMode) {
        return {
          columns: [
            { id: "index", name: "Sr", width: 25, readOnly: true },
            { id: "qty", name: "Mtr", width: 80, skipFocus: true },
            { id: "recon", name: trnType === trnTypes.PURCHASE_RETURN ? "Return" : "Issue", width: 60, handleNextKey: true },
          ],
          allowAddRow: false,
        };
      } else {
        return {
          columns: [
            { id: "sr", name: "Sr", width: 25, readOnly: true },
            { id: "qty", name: "Mtr", width: 120, handleNextKey: true },
            { id: "cancel", width: 25, readOnly: true },
          ],
          allowAddRow: true,
        };
      }
    }

    case trnTypes.MILL_RECEIVE:
    case trnTypes.SALES:
    case trnTypes.JOB_WORK_OUTWARD:
      return {
        columns: [
          { id: "index", name: "Sr", width: 20, readOnly: true },
          { id: "used_qty", name: "Used Mtr", width: 80, skipFocus: true },
          { id: "qty_des", name: "Finish Mtr", width: 120, handleNextKey: true },
          { id: "short_per", name: "Short %", width: 60, handleNextKey: true },
          { id: "excess_short_qty", name: "Excess", width: 60, handleNextKey: true },
        ],
        allowAddRow: false,
      };
    case trnTypes.JOB_WORK_ISSUE:
      if (outstandingStockItemPcs?.length || initialValues?.pcs?.[0]?.prev_pc_id) {
        const columns = [
          { id: "index", name: "Sr", width: 25, readOnly: true },
          showRemarksColumn ? { id: "remarks", name: remarksColumnTitle || "Description", width: 120, readOnly: false } : null,
          { id: "used_qty", name: "Mtr", skipFocus: true, width: 80 },
          { id: "recon", name: "Issue", width: 60, handleNextKey: true },
        ].filter(el => el);
        return {
          columns,
          allowAddRow: false,
        };
      } else {
        const columns = [
          { id: "sr", name: "Sr", width: 25, readOnly: true },
          showRemarksColumn ? { id: "remarks", name: remarksColumnTitle || "Description", width: 120, handleNextKey: true } : null,
          { id: "qty_des", name: "Mtr", width: 120, handleNextKey: true },
          { id: "cancel", width: 25, readOnly: true },
        ].filter(el => el)
        return {
          columns: columns,
          allowAddRow: true,
        };
      }
    case trnTypes.JOB_WORK_RECEIVE:
    case trnTypes.JOB_WORK_RECEIVE_CHALLAN:
      return {
        columns: [
          { id: "index", name: "Sr", width: 20, readOnly: true },
          { id: "used_qty", name: "Used Mtr", width: 80, skipFocus: true },
          { id: "qty_des", name: "Finish Mtr", width: 120, handleNextKey: true },
          { id: "short_per", name: "Short %", width: 60, handleNextKey: true },
        ],
        allowAddRow: false,
      };

    default:
      return {
        columns: [],
      };
  }
};

export const getHasTaxes = (trnType) => {
  switch (trnType) {
    case trnTypes.MILL_ISSUE:
    case trnTypes.CUTTING:
    case trnTypes.JOB_WORK_ISSUE:
    case trnTypes.JOB_WORK_RECEIVE_CHALLAN:
    case trnTypes.JOB_WORK_INWARD:
    case trnTypes.JOB_WORK_OUTWARD:
    case trnTypes.PURCHASE_CHALLAN:
      return false;
    default:
      return true;
  }
};

export const getHasExpenses = (trnType) => {
  switch (trnType) {
    case trnTypes.MILL_ISSUE:
    case trnTypes.CUTTING:
    case trnTypes.JOB_WORK_ISSUE:
    case trnTypes.JOB_WORK_RECEIVE_CHALLAN:
    case trnTypes.JOB_WORK_INWARD:
    case trnTypes.JOB_WORK_OUTWARD:
    case trnTypes.PURCHASE_CHALLAN:
      return false;
    default:
      return true;
  }
};

export const getIsLedgerApplicable = (trnType) => {
  switch (trnType) {
    case trnTypes.SALES:
    case trnTypes.SALES_RETURN:
    case trnTypes.SALES_CREDIT_NOTE:
    case trnTypes.SALES_DEBIT_NOTE:
    case trnTypes.PURCHASE:
    case trnTypes.PURCHASE_RETURN:
    case trnTypes.PURCHASE_CREDIT_NOTE:
    case trnTypes.PURCHASE_DEBIT_NOTE:
    case trnTypes.QUICK_SALES:
    case trnTypes.QUICK_PURCHASE:
    case trnTypes.RECEIPT_ENTRY:
    case trnTypes.PAYMENT_ENTRY:
    case trnTypes.JOURNAL_ENTRY:
    case trnTypes.CONTRA_ENTRY:
    case trnTypes.MILL_RECEIVE:
    case trnTypes.JOB_WORK_RECEIVE:
    case trnTypes.AGENCY_PURCHASE:
    case trnTypes.AGENCY_PURCHASE_RETURN:
    case trnTypes.AGENCY_PAYMENT:
      return true;
    default:
      return false;
  }
};

export const getTrnLabel = (trnType) => {
  switch (trnType) {
    case trnTypes.SALES:
      return "Sales";
    case trnTypes.SALES_ORDER:
      return "Sales Order";
    case trnTypes.SALES_CHALLAN:
      return "Sales Challan";
    case trnTypes.SALES_RETURN:
      return "Sales Return";
    case trnTypes.EXCESS_SALES_RETURN:
      return "Excess RG";
    case trnTypes.PURCHASE:
      return "Purchase";
    case trnTypes.PURCHASE_ORDER:
      return "Purchase Order";
    case trnTypes.PURCHASE_CHALLAN:
      return "Purchase Challan";
    case trnTypes.PURCHASE_RETURN:
      return "Purchase Return";
    case trnTypes.SALES_CREDIT_NOTE:
      return "Sales Credit Note";
    case trnTypes.SALES_DEBIT_NOTE:
      return "Sales Debit Note";
    case trnTypes.PURCHASE_CREDIT_NOTE:
      return "Purchase Credit Note";
    case trnTypes.PURCHASE_DEBIT_NOTE:
      return "Purchase Debit Note";
    case trnTypes.QUICK_SALES:
      return "Quick Sales";
    case trnTypes.QUICK_PURCHASE:
      return "Quick Purchase";
    case trnTypes.RECEIPT_ENTRY:
      return "Receipt Entry";
    case trnTypes.PAYMENT_ENTRY:
      return "Payment Entry";
    case trnTypes.CHEQUE_RETURN_ENTRY:
      return "Cheque Return Entry"
    case trnTypes.JOURNAL_ENTRY:
      return "Journal Entry";
    case trnTypes.CONTRA_ENTRY:
      return "Contra Entry";
    case trnTypes.MILL_ISSUE:
      return "Mill Issue";
    case trnTypes.MILL_RECEIVE:
      return "Mill Receive";
    case trnTypes.CUTTING:
      return "Cutting";
    case trnTypes.JOB_WORK_ISSUE:
      return "Job Issue";
    case trnTypes.JOB_WORK_RECEIVE_CHALLAN:
      return "Job Receive";
    case trnTypes.JOB_WORK_RECEIVE:
      return "Job Bill";
    case trnTypes.JOB_WORK_INWARD:
      return "Job Inward";
    case trnTypes.JOB_WORK_OUTWARD:
      return "Job Outward";
    case trnTypes.STOCK_INWARD_ENTRY:
      return "Stock Inward Entry";
    case trnTypes.STOCK_ASSEMBLY_ENTRY:
      return "Stock Assembly Entry";
    case trnTypes.STOCK_MOVEMENT_ENTRY:
      return "Stock Movement Entry";
    case trnTypes.AGENCY_PURCHASE:
      return "Agency Purchase Entry";
    case trnTypes.AGENCY_PURCHASE_ORDER:
      return "Agency Purchase Order Entry";
    case trnTypes.AGENCY_PURCHASE_RETURN:
      return "Agency Purchase Return Entry";
    case trnTypes.AGENCY_PAYMENT:
      return "Agency Payment Entry";
    case trnTypes.COURIER:
      return "Courier";
    default:
      return "";
  }
};

export const getDefaultAccountTypeForParty = (trnType) => {
  switch (trnType) {
    case trnTypes.SALES:
    case trnTypes.QUICK_SALES:
    case trnTypes.SALES_RETURN:
    case trnTypes.EXCESS_SALES_RETURN:
    case trnTypes.SALES_CREDIT_NOTE:
    case trnTypes.SALES_DEBIT_NOTE:
    case trnTypes.RECEIPT_ENTRY:
    case trnTypes.SALES_ORDER:
    case trnTypes.JOB_WORK_INWARD:
    case trnTypes.JOB_WORK_OUTWARD:
      return "DBT";
    case trnTypes.MILL_ISSUE:
    case trnTypes.MILL_RECEIVE:
      return "MIL";
    case trnTypes.JOB_WORK_ISSUE:
    case trnTypes.JOB_WORK_RECEIVE:
      return "JBW";
    default:
      return "SPL";
  }
};

export const getAccountSubTypesForBook = (trnType) => {
  switch (trnType) {
    case trnTypes.QUICK_SALES:
      return [trnTypes.SALES];
    case trnTypes.EXCESS_SALES_RETURN:
      return [trnTypes.SALES_RETURN];
    case trnTypes.QUICK_PURCHASE:
      return [trnTypes.PURCHASE];
    case trnTypes.RECEIPT_ENTRY:
    case trnTypes.PAYMENT_ENTRY:
      return ["CASH", "BANK"];
    case trnTypes.CHEQUE_RETURN_ENTRY:
      return ["BANK"]
    default:
      return [trnType];
  }
};

export const getItemUnitId = (trnType, item) => {
  switch (trnType) {
    case trnTypes.SALES:
    case trnTypes.SALES_ORDER:
    case trnTypes.SALES_RETURN:
    case trnTypes.EXCESS_SALES_RETURN:
    case trnTypes.SALES_CREDIT_NOTE:
    case trnTypes.SALES_DEBIT_NOTE:
      return item.s_unit_id ? item.s_unit_id : undefined;
    default:
      return item.p_unit_id ? item.p_unit_id : undefined;
  }
};

export const getItemRateOn = (trnType, item, units) => {
  switch (trnType) {
    case trnTypes.SALES:
    case trnTypes.SALES_ORDER:
    case trnTypes.QUICK_SALES:
    case trnTypes.SALES_RETURN:
    case trnTypes.EXCESS_SALES_RETURN:
    case trnTypes.SALES_CREDIT_NOTE:
    case trnTypes.SALES_DEBIT_NOTE:
    case trnTypes.JOB_WORK_ISSUE: {
      const unit = units.find((obj) => obj.id === item.s_unit_id);
      return unit ? unit.details.qp : undefined;
    }
    default: {
      const unit = units.find((obj) => obj.id === item.p_unit_id);
      return unit ? unit.details.qp : undefined;
    }
  }
};

export const getItemRate = (trnType, item, rcId) => {
  switch (trnType) {
    case trnTypes.SALES:
    case trnTypes.SALES_RETURN:
    case trnTypes.EXCESS_SALES_RETURN:
    case trnTypes.SALES_ORDER:
      return item.s_rates?.[rcId] || item.s_rate;
    case trnTypes.PURCHASE:
    case trnTypes.PURCHASE_RETURN:
    case trnTypes.MILL_RECEIVE:
      return item.p_rate;
    default:
      return null;
  }
};

export const getOutstandingTrnTypesForAmtReconciliation = (trnType) => {
  switch (trnType) {
    case trnTypes.SALES:
    case trnTypes.SALES_DEBIT_NOTE:
      return [
        trnTypes.PURCHASE,
        trnTypes.PURCHASE_CREDIT_NOTE,
        trnTypes.MILL_ISSUE,
        trnTypes.JOB_WORK_RECEIVE,
        trnTypes.QUICK_PURCHASE,
        trnTypes.JOURNAL_ENTRY,
      ];
    case trnTypes.PURCHASE:
    case trnTypes.PURCHASE_CREDIT_NOTE:
      return [trnTypes.SALES, trnTypes.SALES_DEBIT_NOTE, trnTypes.QUICK_SALES, trnTypes.JOURNAL_ENTRY];
    case trnTypes.SALES_RETURN:
    case trnTypes.SALES_CREDIT_NOTE:
      return [
        trnTypes.SALES,
        trnTypes.QUICK_SALES,
        trnTypes.SALES_DEBIT_NOTE,
        trnTypes.PURCHASE_RETURN,
        trnTypes.PURCHASE_DEBIT_NOTE,
      ];
    case trnTypes.PURCHASE_RETURN:
    case trnTypes.PURCHASE_DEBIT_NOTE:
      return [
        trnTypes.PURCHASE,
        trnTypes.QUICK_PURCHASE,
        trnTypes.PURCHASE_CREDIT_NOTE,
        trnTypes.MILL_RECEIVE,
        trnTypes.JOB_WORK_RECEIVE,
        trnTypes.SALES_RETURN,
        trnTypes.SALES_CREDIT_NOTE,
      ];
    case trnTypes.RECEIPT_ENTRY: {
      const types = [
        trnTypes.SALES,
        trnTypes.SALES_DEBIT_NOTE,
        trnTypes.PURCHASE_RETURN,
        trnTypes.PURCHASE_DEBIT_NOTE,
        trnTypes.QUICK_SALES,
        trnTypes.PAYMENT_ENTRY,
        trnTypes.JOURNAL_ENTRY,
      ];
      return types;
    }
    case trnTypes.PAYMENT_ENTRY:
      return [
        trnTypes.PURCHASE,
        trnTypes.PURCHASE_CREDIT_NOTE,
        trnTypes.MILL_RECEIVE,
        trnTypes.JOB_WORK_RECEIVE,
        trnTypes.SALES_RETURN,
        trnTypes.SALES_CREDIT_NOTE,
        trnTypes.QUICK_PURCHASE,
        trnTypes.RECEIPT_ENTRY,
        trnTypes.JOURNAL_ENTRY,
      ];
    case trnTypes.CONTRA_ENTRY:
      return [
        trnTypes.SALES,
        trnTypes.SALES_DEBIT_NOTE,
        trnTypes.SALES_RETURN,
        trnTypes.PURCHASE,
        trnTypes.PURCHASE_RETURN,
        trnTypes.PURCHASE_CREDIT_NOTE,
        trnTypes.MILL_RECEIVE,
        trnTypes.JOB_WORK_RECEIVE,
        trnTypes.QUICK_SALES,
        trnTypes.QUICK_PURCHASE,
      ];
    case trnTypes.AGENCY_PAYMENT:
      return [
        trnTypes.AGENCY_PURCHASE,
        trnTypes.AGENCY_PURCHASE_RETURN,
        trnTypes.AGENCY_PAYMENT
      ]
    case trnTypes.AGENCY_PURCHASE_RETURN:
      return [
        trnTypes.AGENCY_PURCHASE
      ]
    default:
      return [];
  }
};

export const getOutstandingTrnTypesForStockReconiliation = (trnType) => {
  switch (trnType) {
    case trnTypes.MILL_ISSUE:
      return [trnTypes.PURCHASE, trnTypes.MILL_RECEIVE, trnTypes.JOB_WORK_RECEIVE, trnTypes.JOB_WORK_RECEIVE_CHALLAN, trnTypes.STOCK_INWARD_ENTRY];
    case trnTypes.MILL_RECEIVE:
      return [trnTypes.MILL_ISSUE];
    case trnTypes.CUTTING:
      return [trnTypes.MILL_RECEIVE];
    case trnTypes.JOB_WORK_RECEIVE_CHALLAN:
      return [trnTypes.JOB_WORK_ISSUE];
    case trnTypes.JOB_WORK_RECEIVE:
      return [trnTypes.JOB_WORK_ISSUE];
    default:
      return [];
  }
};

export const getCanPerformAmtReconciliation = (trnType) => {
  switch (trnType) {
    case trnTypes.RECEIPT_ENTRY:
    case trnTypes.PAYMENT_ENTRY:
    case trnTypes.SALES_RETURN:
    case trnTypes.PURCHASE_RETURN:
    case trnTypes.SALES_CREDIT_NOTE:
    case trnTypes.PURCHASE_DEBIT_NOTE:
    case trnTypes.CONTRA_ENTRY:
      return true;
    default:
      return false;
  }
};

export const getCanTrnAmtBeReconciled = (trnType) => {
  switch (trnType) {
    case trnTypes.SALES:
    case trnTypes.QUICK_SALES:
    case trnTypes.SALES_DEBIT_NOTE:
    case trnTypes.PURCHASE:
    case trnTypes.QUICK_PURCHASE:
    case trnTypes.PURCHASE_CREDIT_NOTE:
    case trnTypes.MILL_RECEIVE:
    case trnTypes.JOB_WORK_RECEIVE:
      return true;
    default:
      return false;
  }
};

export const getGSTDetail = (invoiceType, itemId, billDate, itemTaxesMap) => {
  const gstDetail = { itm_tax_rate: 0, rate: 0, cess_rate: 0 };
  const taxes = itemTaxesMap[itemId]?.taxes ?? [];

  const taxIndex = taxes
    .reverse()
    .findIndex((obj) => new Date(obj.date).getTime() <= new Date(billDate).getTime());
  if (taxIndex > -1) {
    gstDetail.rate = taxes[taxIndex].rate;
    gstDetail.itm_tax_rate = gstDetail.rate;
    gstDetail.cess_rate = taxes[taxIndex].cess_rate;
  }

  if (invoiceType === "DEEMED EXPORT") {
    gstDetail.rate = 0.1;
  }

  return gstDetail;
};

export const getGSTDetailFromHsnCode = (invoiceType, billDate, taxDetailsMap, hsnId) => {
  const gstDetail = { itm_tax_rate: 0, rate: 0, cess_rate: 0 };
  const hsnDetail = getHSNDetails(store.getState()).find(obj => obj.id === hsnId);
  const taxes = hsnDetail?.details?.taxes.map((obj) => ({
    ...obj,
    ...(taxDetailsMap[obj.tax_id] ? taxDetailsMap[obj.tax_id] : {}),
  }));
  const taxIndex = taxes.reverse().findIndex((obj) => new Date(obj.date).getTime() <= new Date(billDate).getTime());
  if (taxIndex > -1) {
    gstDetail.rate = taxes[taxIndex].rate;
    gstDetail.itm_tax_rate = gstDetail.rate;
    gstDetail.cess_rate = taxes[taxIndex].cess_rate;
  }

  if (invoiceType === "DEEMED EXPORT") {
    gstDetail.rate = 0.1;
  }

  return gstDetail;
};

export const getCompleteChequeNo = (chqno) => {
  if (!chqno) {
    return;
  }
  const isTrnModeCheque = !Number.isNaN(Number(chqno));
  let newChequeNo = chqno;
  if (isTrnModeCheque) {
    newChequeNo = String(chqno).padStart(6, "0");
  } else {
    switch (newChequeNo) {
      case "C":
        newChequeNo = "CASH";
        break;
      case "N":
        newChequeNo = "NEFT";
        break;
      case "R":
        newChequeNo = "RTGS";
        break;
      case "I":
        newChequeNo = "IMPS";
        break;
      case "U":
        newChequeNo = "UPI";
        break;
    }
  }
  return newChequeNo;
};

export const getDaybookLotSize = (bookId) => {
  const daybook = getDaybookSetting(store.getState(), bookId);
  return daybook.settings?.lot_size;
};

export const stripGroupNameFromItemName = (itemName, groupName) => {
  return itemName?.split(groupName).join("").trim();
};

export const checkIfGstNoIsValidForDate = (gstRegDate, gstCanDate, trnDate) => {
  if ((gstRegDate && trnDate < gstRegDate) || (gstCanDate && trnDate >= gstCanDate)) {
    return false;
  }
  return true;
}

export const generateCollissionFreeId = (idsMap) => {
  const id = nanoid();
  if (idsMap[id]) {
    return generateCollissionFreeId(idsMap);
  } else {
    idsMap[id] = true;
    return id;
  }
}

export const duplicateTransaction = async (id, onSuccess) => {
  try {
    incrementPendingRequests();
    if (!id) {
      notifyError("No transaction selected!");
      return;
    }
    const {
      type,
      book_id,
      party_id,
      party_gst_no,
      broker_id,
      contact_id,
      salesperson_id,
      deli_id,
      inv_type,
      items = [],
      pcs,
      qty,
      amt,
      expenses,
      taxable_amt,
      inv_amt,
      net_amt,
      trans_id,
      station,
      bundle,
      remarks,
      custom_fields,
    } = await fetchTransaction(id);
    const lineIdsMap = {};
    const newItems = items.map(obj => {
      return {
        ...obj, id: generateCollissionFreeId(lineIdsMap),
      }
    });
    const [nextVoucherNo, field] = await fetchNextVoucherNo(type, book_id);
    const currDate = format(new Date(), "yyyy-MM-dd");
    let date = currDate;
    const latestTrn = getTransactions(store.getState(), type)[0];
    // If last entry created today then take date from last entry else current date
    if (latestTrn && latestTrn.created_at && latestTrn.created_at >= currDate && latestTrn.date) {
      date = latestTrn.date;
    }
    const newTrn = {
      type,
      book_id,
      party_id,
      party_gst_no,
      broker_id,
      contact_id,
      salesperson_id,
      deli_id,
      inv_type,
      date,
      [field]: nextVoucherNo,
      items: newItems,
      pcs,
      qty,
      amt,
      expenses,
      taxable_amt,
      inv_amt,
      net_amt,
      trans_id,
      station,
      bundle,
      remarks,
      custom_fields,
    };
    const handleOnSuccess = (_, history) => {
      history?.goBack();
      onSuccess?.();
    }
    const trnTypeName = getTrnLabel(type);
    addAppTab(`/transaction/${type}/new`, trnTypeName, { defaultValues: newTrn, onSuccess: handleOnSuccess });
  } finally {
    decrementPendingRequests();
  }
}

export const createJobReceiveChallanFromJobIssue = async (id, onSuccess) => {
  try {
    incrementPendingRequests();
    if (!id) {
      notifyError("No transaction selected!");
      return;
    }
    const type = trnTypes.JOB_WORK_RECEIVE_CHALLAN;
    const {
      org_id,
      fy_id,
      party_id,
      party_gst_no,
      broker_id,
      contact_id,
      pcs,
      qty,
      amt,
      taxable_amt,
      inv_amt,
      net_amt,
      remarks,
      custom_fields,
    } = await fetchTransaction(id);
    const date = format(new Date(), "yyyy-MM-dd");
    const outstandingTrnTypes = getOutstandingTrnTypesForStockReconiliation(type);
    const outstandingStockItems = await fetchOutstandingStockItemsByParty(
      org_id, fy_id, { date, party_id, types: outstandingTrnTypes, loc: "JOB" },
    );
    const trnOutstandingStockItems = outstandingStockItems.filter(obj => obj.trn_id === id);
    if (!trnOutstandingStockItems.length) {
      notifyError("No balance items in this transaction");
      return;
    }
    const lineIdsMap = {};
    const newItems = trnOutstandingStockItems.map(stockRecord => {
      const {
        id,
        trn_id,
        trn_itm_id,
        src_trn_itm_id,
        refno,
        lotno,
        itm_id,
        cut,
        weight,
        pcs,
        qty,
        iss_rate,
        iss_qp,
        iss_unit_id,
        screen_id,
      } = stockRecord;
      return {
        party_id,
        itm_id,
        cut,
        weight,
        refno,
        lotno,
        trn_from: trn_id,
        prev_trn_itm_id: trn_itm_id,
        src_trn_itm_id: src_trn_itm_id || trn_itm_id,
        prev_stk_id: id,
        used_pcs: pcs,
        used_qty: qty,
        pcs,
        qty,
        fin_itm_id: itm_id,
        screen_id,
        rate: iss_rate,
        qp: iss_qp,
        unit_id: iss_unit_id,
        id: generateCollissionFreeId(lineIdsMap),
      }
    });
    const state = store.getState();
    const daybooks = getDaybooksForTrnType(state, type);
    const newTrn = {
      type,
      party_id,
      party_gst_no,
      broker_id,
      contact_id,
      date: format(new Date(), "yyyy-MM-dd"),
      items: newItems,
      pcs,
      qty,
      amt,
      taxable_amt,
      inv_amt,
      net_amt,
      remarks,
      custom_fields,
    };
    if (daybooks.length === 1) {
      const bookId = daybooks[0].id;
      const [nextVoucherNo, field] = await fetchNextVoucherNo(type, bookId);
      newTrn.book_id = bookId;
      newTrn[field] = nextVoucherNo;
    }
    const trnTypeName = getTrnLabel(type);
    const handleOnSuccess = (trnDetails, history) => {
      onSuccess?.();
      showYesNoConfimationModal({
        title: "Forward received goods to another job work?",
        onOk: () => {
          createJobIssueChallanFromJobReceive(trnDetails.id)
          history?.goBack();
        },
        onCancel: () => history?.goBack(),
      })
    }
    addAppTab(`/transaction/${type}/new`, trnTypeName, { defaultValues: newTrn, onSuccess: handleOnSuccess });
  } finally {
    decrementPendingRequests();
  }
}

export const createJobIssueChallanFromJobReceive = async (id, onSuccess) => {
  try {
    incrementPendingRequests();
    if (!id) {
      notifyError("No transaction selected!");
      return;
    }
    const type = trnTypes.JOB_WORK_ISSUE;
    const {
      org_id,
      fy_id,
      party_id,
      broker_id,
      contact_id,
      pcs,
      qty,
      amt,
      taxable_amt,
      inv_amt,
      net_amt,
      remarks,
      custom_fields,
    } = await fetchTransaction(id);
    const date = format(new Date(), "yyyy-MM-dd");
    const outstandingTrnTypes = getOutstandingTrnTypesForStockReconiliation(type);
    const outstandingStockItems = await fetchOutstandingStockItemsByParty(
      org_id, fy_id, { date, party_id, types: outstandingTrnTypes, loc: "WH" },
    );
    const trnOutstandingStockItems = outstandingStockItems.filter(obj => obj.trn_id === id);
    if (!trnOutstandingStockItems.length) {
      notifyError("No balance items in this transaction");
      return;
    }
    const lineIdsMap = {};
    const newItems = trnOutstandingStockItems.map(stockRecord => {
      const {
        id,
        trn_id,
        trn_itm_id,
        src_trn_itm_id,
        refno,
        lotno,
        itm_id,
        cut,
        weight,
        pcs,
        qty,
        rate,
        qp,
        unit_id,
        screen_id,
        value,
      } = stockRecord;
      return {
        party_id,
        itm_id,
        cut,
        weight,
        refno,
        lotno,
        trn_from: trn_id,
        prev_trn_itm_id: trn_itm_id,
        src_trn_itm_id: src_trn_itm_id || trn_itm_id,
        prev_stk_id: id,
        used_pcs: pcs,
        used_qty: qty,
        pcs,
        qty,
        fin_itm_id: itm_id,
        screen_id,
        rate,
        qp,
        unit_id,
        amt: value,
        id: generateCollissionFreeId(lineIdsMap),
      }
    });
    const state = store.getState();
    const daybooks = getDaybooksForTrnType(state, type);
    const newTrn = {
      type,
      broker_id,
      contact_id,
      date: format(new Date(), "yyyy-MM-dd"),
      items: newItems,
      pcs,
      qty,
      amt,
      taxable_amt,
      inv_amt,
      net_amt,
      remarks,
      custom_fields,
    };
    if (daybooks.length === 1) {
      const daybook = daybooks[0];
      const bookId = daybook.id;
      newTrn.book_id = bookId;
      const bookSettings = daybook.settings;
      if (bookSettings?.continue_chlno && trnOutstandingStockItems[0]?.chlno) {
        const [rootChlno, subChlno] = trnOutstandingStockItems[0].chlno.split("/");
        const subChlNos = ["", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P"];
        const subChlNoIndex = subChlNos.indexOf(subChlno?.trim() || "");
        const newSubChlNo = subChlNos[subChlNoIndex + 1];
        const newChlNo = [rootChlno, newSubChlNo].join("/");
        newTrn.refno = newChlNo;
      } else {
        const [nextVoucherNo] = await fetchNextVoucherNo(type, bookId);
        newTrn.refno = nextVoucherNo;
      }
    }
    const trnTypeName = getTrnLabel(type);
    const handleOnSuccess = (_, history) => {
      history?.goBack();
      onSuccess?.();
    }
    addAppTab(`/transaction/${type}/new`, trnTypeName, { defaultValues: newTrn, onSuccess: handleOnSuccess });
  } finally {
    decrementPendingRequests();
  }
}

const DEFAULT_MAX_SHORTAGE = 15;

export const getMaxShortage = (items, itemId) => {
  if (!itemId || !items) {
    return DEFAULT_MAX_SHORTAGE;
  }
  const itemDetails = items.find((obj) => obj.id === itemId);
  return itemDetails?.max_short_per || DEFAULT_MAX_SHORTAGE;
};

export const getDocumentTypes = (trnType) => {
  switch (trnType) {
    case trnTypes.SALES:
      return [
        { id: "SALES_INVOICE", name: "Bill" },
        { id: "TRANSPORT_SLIP", name: "Transport Slip" },
        { id: "DELIVERY_CHALLAN", name: "Delivery Challan" },
        { id: "PACKING_SLIP", name: "Packing Slip" },
        { id: "PARCEL_POSTER", name: "Parcel Poster" },
        { id: "EWAY_BILL", name: "E Way Bill" },
      ];
    case trnTypes.SALES_RETURN:
    case trnTypes.PURCHASE_RETURN:
      return [{ id: "RETURN_GOODS", name: "Return Goods" }, { id: "EWAY_BILL", name: "E Way Bill" }];
    case trnTypes.EXCESS_SALES_RETURN:
      return [{ id: "RETURN_GOODS", name: "Return Goods" }];
    case trnTypes.SALES_CREDIT_NOTE:
    case trnTypes.SALES_DEBIT_NOTE:
    case trnTypes.PURCHASE_CREDIT_NOTE:
    case trnTypes.PURCHASE_DEBIT_NOTE:
      return [{ id: "CREDIT_DEBIT_NOTE", name: "Credit/Debit Note" }];
    case trnTypes.MILL_ISSUE:
      return [
        { id: "MILL_CHALLAN", name: "Mill Challan" },
        { id: "CUTTING_CARD", name: "Cutting Card" },
        { id: "EWAY_BILL", name: "E Way Bill" },
      ];
    case trnTypes.MILL_RECEIVE:
      return [
        { id: "CUTTING_CARD", name: "Cutting Card" },
        { id: "PURCHASE_BILL", name: "Bill" },
      ];
    case trnTypes.JOB_WORK_ISSUE:
      return [{ id: "JOB_CHALLAN", name: "Job Challan" }, { id: "TRANSPORT_SLIP", name: "Transport Slip" }];
    case trnTypes.JOB_WORK_RECEIVE_CHALLAN:
      return [
        { id: "JOB_RECEIVE_CHALLAN", name: "Challan" },
        { id: "JOB_RECEIVE_TRANSPORT_SLIP", name: "Transport Slip" }
      ];
    case trnTypes.JOB_WORK_INWARD:
      return [{ id: "JOB_INWARD", name: "Job Inward" }];
    case trnTypes.JOB_WORK_OUTWARD:
      return [{ id: "JOB_OUTWARD", name: "Job Outward" }, { id: "TRANSPORT_SLIP", name: "Transport Slip" }];
    case trnTypes.PURCHASE:
    case trnTypes.JOB_WORK_RECEIVE:
      return [{ id: "PURCHASE_BILL", name: "Bill" }];
    case trnTypes.PAYMENT_ENTRY:
      return [
        { id: "CHEQUE", name: "Cheque" },
        { id: "PAYMENT_SLIP", name: "Payment Slip" },
      ];
    case trnTypes.RECEIPT_ENTRY:
      return [
        { id: "PAYMENT_SLIP", name: "Payment Slip" },
      ];
    case trnTypes.QUICK_SALES:
      return [{ id: "QUICK_VOUCHER", name: "Voucher" }];
    case trnTypes.SALES_ORDER:
      return [
        { id: "ORDER", name: "Order" },
      ];
    case trnTypes.PURCHASE_ORDER:
      return [
        { id: "PURCHASE_ORDER", name: "Order" },
      ];
    case trnTypes.AGENCY_PAYMENT:
      return [
        { id: "AGENCY_PAYMENT_VOUCHER", name: "Payment Voucher" }
      ]
    case trnTypes.AGENCY_PURCHASE_ORDER:
      return [
        { id: "AGENCY_ORDER", name: "Order" }
      ]
    case trnTypes.AGENCY_PURCHASE:
      return [
        { id: "AGENCY_PURCHASE_VOUCHER", name: "Purchase Voucher" }
      ]
    case trnTypes.AGENCY_PURCHASE_RETURN:
      return [
        { id: "AGENCY_RG_VOUCHER", name: "RG Voucher" }
      ]
    case trnTypes.JOURNAL_ENTRY:
      return [
        { id: "JOURNAL_VOUCHER", name: "Journal Voucher" }
      ]
    case trnTypes.SALES_CHALLAN:
      return [
        { id: "SALES_CHALLAN", name: "Sales Challan" },
      ]
    case trnTypes.PURCHASE_CHALLAN:
      return [
        { id: "PURCHASE_CHALLAN", name: "Purchase Challan" },
      ]
    case trnTypes.COURIER:
      return [
        { id: "COURIER_DISPATCH_LETTER", name: "Courier Dispatch Letter" },
        { id: "COURIER_RECEIPT_SLIP", name: "Courier Receipt Slip" },
      ];
    case trnTypes.CUTTING:
      return [
        { id: "CUTTING_REPORT", name: "Cutting Report" },
      ]
    default:
      return [];
  }
};
